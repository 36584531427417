<template>
    <div class="container-fluid main-color">
        <!-- start page title -->
        <div class="row">
            <div class="col-12">
                <div class="page-title-box">
                    <h4 class="page-title">Evaluation History Detail</h4>
                </div>
            </div>
        </div>
        <!-- end page title -->
        <div class="row">
            <div class="col-lg-10">
                <place-holder v-if="loading"></place-holder>
                <div class="card" v-if="!loading">
                    <div v-if="!loading" class="card-body">
                        <form class="parsley-examples">
                            <div class="row mb-3">
                                <label for="name" class="col-md-3 form-label">
                                    Module Name
                                </label>
                                <div class="col-md-7">
                                    <!-- <input type="text" id="name" name="name" class="form-control" placeholder="Program Name"
                                        v-model="approvalLists.program_name" disabled /> -->
                                    <span>{{ evaluationHistory.module_name }}</span>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <label for="issued_date" class="col-md-3 form-label">
                                    Batch
                                </label>
                                <div class="col-md-7">
                                    <span>{{ evaluationHistory.batch }}</span>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <label for="issued_date" class="col-md-3 form-label">
                                    Trainer
                                </label>
                                <div class="col-md-7">
                                    <span v-for="(trainer, index) in evaluationHistory.trainer" :key="index">{{
                                        trainer.name
                                    }}
                                        <span v-if="index + 1 < evaluationHistory.trainer.length">, </span>
                                    </span>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <label for="requested" class="col-md-3 form-label">
                                    Suggestion
                                </label>
                                <div class="col-md-7">
                                    <span>{{ evaluationHistory.suggestion }}</span>
                                </div>
                            </div>
                            
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                    <th scope="col">Evaluation Criteria</th>
                                    <th scope="col" class="text-center">Score</th>
                                    </tr>
                                </thead>
                                <tbody v-for="(item, index) in evaluationHistory.evaluation_results"
                                :key="index">
                                    <tr>
                                    <td>{{ item.criteria_name }}</td>
                                    <td class="text-center">{{ item.score }}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <!-- <div class="row mb-3" v-for="(item, index) in evaluationHistory.evaluation_results"
                                :key="index">
                                <label for="issued_date" class="col-md-3 form-label">
                                    {{ item.criteria_name }}
                                </label>
                                <div class="col-md-7">
                                    <span>{{ item.score }}</span>
                                </div>
                            </div> -->
                            <!-- end row -->
                        </form>
                    </div>

                    <div class="page-title-right">
                        <router-link :to="{
                            name: 'evaluation-history',
                        }">
                            <button type="button" class="btn btn-sm btn-blue waves-effect waves-light mb-4 float-end me-5">
                                <i class="mdi mdi-arrow-left"></i>
                                Back
                            </button>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import axios from "axios";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";

export default {
    setup() {
        const toast = useToast();
        return { v$: useVuelidate(), toast };
    },
    components: {
    },
    data() {
        return {
            evaluationHistory: "",
            baseUrl: process.env.VUE_APP_BASE_URL,
            loading: false,
        };
    },
    methods: {
        async getEvaluationHistoryById() {
            this.loading = true;
            await axios
                .get(`${this.baseUrl}admin/v1/evaluation-history/${this.$route.params.id}`)
                .then((response) => {
                    const result = response.data.data;
                    this.evaluationHistory = result;
                })
                .catch(() => {
                    this.toast.error("Not Found Program!");
                });
            this.loading = false;
        },
    },
    created() {
        this.getEvaluationHistoryById();
    },
};
</script>

<style>
.form-control:disabled,
.form-control[readonly] {
    background-color: #efefef;
    opacity: 1;
    cursor: not-allowed;
}
</style>